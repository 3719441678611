<template>
  <div class="app-container">
    <el-tabs v-model="listQuery.type" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="固定资产" :name="1"></el-tab-pane>
      <el-tab-pane label="无形资产" :name="2"></el-tab-pane>
      <el-tab-pane label="费用摊销" :name="3"></el-tab-pane>
      <div class="left_search">
        <el-input size="small" v-model="listQuery.name" placeholder="请输入费用名称" v-if="listQuery.type == 3" style="width:120px"></el-input>
        <el-input size="small" v-model="listQuery.name" placeholder="请输入资产名称" v-else style="width:120px" @keyup.enter="getList"></el-input>
        <qzf-button type="primary" size="small" @success="getList">
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </qzf-button>
        <el-select v-model="listQuery.assetType" placeholder="请选择费用类型" style="width:140px" size="small" @change="getList" clearable v-if="listQuery.type == 3">
          <el-option label="开办费" value="开办费"></el-option>
          <el-option label="固定资产修理支出" value="固定资产修理支出"></el-option>
          <el-option label="租入固定资产的改良支出" value="租入固定资产的改良支出"></el-option>
          <el-option label="已足额折旧资产改建" value="已足额折旧资产改建"></el-option>
          <el-option label="其他" value="其他"></el-option>
        </el-select>
        <el-select v-model="listQuery.assetType" placeholder="请选择资产类别" style="width:140px" size="small" @change="getList" clearable v-else>
          <el-option label="房屋、建筑物" value="房屋、建筑物"></el-option>
          <el-option label="飞机、火车、轮船、机器、机械和其他生产设备" value="飞机、火车、轮船、机器、机械和其他生产设备"></el-option>
          <el-option label="生产经营活动有关的器具、工具、家具" value="生产经营活动有关的器具、工具、家具"></el-option>
          <el-option label="飞机、火车、轮船以外的运输工具" value="飞机、火车、轮船以外的运输工具"></el-option>
          <el-option label="电子设备" value="电子设备"></el-option>
          <el-option label="其他" value="其他"></el-option>
        </el-select>
        <p style="display:inline-block;font-size:14px;color:red;line-height:28px;margin-left:10px">温馨提示：<span style="color:#333;">非期初的资产通过【发票】-【进项】增加</span></p>
      </div>
      <div class="right_btns">
        <qzf-video v-if="this.listQuery.type == 1" vid="4467be53745ca91fd9fdc3ee2002f393_4"></qzf-video>
        <qzf-video v-if="this.listQuery.type == 2" vid="4467be53744382b1e11fb51b9ce2f720_4"></qzf-video>
        <qzf-video v-if="this.listQuery.type == 3" vid="4467be537465c94c4d520cbd4b5013aa_4"></qzf-video>


        <el-dropdown split-button size="small" type="success" style="margin-right:10px" :hide-on-click="false" @click="handleCreate">
          <i class="iconfont icon-jiahao"></i> 添加
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <qzf-button jz="true" size="small" type="text" @success="openCom">导入</qzf-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <qzf-button type="danger" size="small" v-if="listQuery.type == 1" @success="assetsDelete"   >
          <el-icon><Delete /></el-icon> <span  >批量删除</span>
        </qzf-button>
        <!-- <qzf-button type="primary" size="small" plain>导出</qzf-button> -->
        <!-- <qzf-button type="primary" size="small" plain v-if="listQuery.type == 1">导入</qzf-button> -->
        <qzf-button type="primary" size="small"  @click="printAssets">
          <el-icon><Printer /></el-icon> <span  >打印</span>
        </qzf-button>
        <!-- <qzf-button type="primary" size="small"  @click="printAssets">
          <el-icon><Printer /></el-icon> <span  >打印凭证纸</span>
        </qzf-button> -->
        <qzf-button type="primary" size="small"  @click="exportAssets">
          <el-icon><FolderOpened /></el-icon> <span  >导出</span>
        </qzf-button>
        <el-dropdown @command="handleCommand" size="small" v-if="listQuery.type == 1" style="margin-left:10px">
          <el-button size="small" type="primary">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu style="text-align:center">
              <el-dropdown-item command="a" style="margin-bottom:5px">
                <span>一键折完</span>
              </el-dropdown-item>
              <el-dropdown-item command="b" style="margin-bottom:5px">
                <span style="margin-top:3px" plain>取消折旧</span>
              </el-dropdown-item>
              <el-dropdown-item command="c" style="margin-bottom:5px">
                <span style="margin-top:3px" plain>一键正常</span>
              </el-dropdown-item>
              <el-dropdown-item command="d" style="margin-bottom:5px">
                <span style="margin-top:3px" plain>一键暂停</span>
              </el-dropdown-item>
              <el-dropdown-item command="e" style="margin-bottom:5px">
                <span style="margin-top:3px" plain>结转固定资产</span>
              </el-dropdown-item>
              <el-dropdown-item command="f" style="margin-bottom:5px">
                <span style="margin-top:3px" plain>固定资产清理</span>
              </el-dropdown-item>
              <!-- <el-dropdown-item command="g" style="margin-bottom:5px">
                <span style="margin-top:3px" plain>导出</span>
              </el-dropdown-item> -->
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <el-table stripe :data="list" style="width: 100%" show-summary :summary-method="getSummaries" border fit highlight-current-row :height="contentStyleObj" @selection-change="handleSelectionChange">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="selection" align="center" width="55"/>
        <el-table-column label="序号" type="index" align="center" width="55"></el-table-column>
        <el-table-column prop="name" v-if="listQuery.type == 3" label="费用名称" align="center" min-width="90">
          <template #default="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" v-else label="资产名称" align="center" min-width="90">
          <template #default="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="assetType" v-if="listQuery.type == 3" label="费用类型" align="center" width="150">
          <template #default="scope">
            <span>{{ scope.row.assetType }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" v-else label="资产类型" align="center" width="150">
          <template #default="scope">
            <span>{{ scope.row.assetType }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="subjectName" v-if="listQuery.type == 1" label="累计折旧科目" align="center" width="150">
          <template #default="scope">
            <span>{{ scope.row.subjectName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" v-else label="累计摊销科目" align="center" width="150">
          <template #default="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="fySubjectName" v-if="listQuery.type == 1" label="折旧费用科目" align="center" width="150">
          <template #default="scope">
            <span>{{ scope.row.fySubjectName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="codeFy" v-else label="摊销费用科目" align="center" width="150">
          <template #default="scope">
            <span>{{ scope.row.codeFy }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="count" label="数量" align="center" width="60"> 
          <template #default="scope">
            <span>{{ scope.row.count }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="transDate" label="入账日期" align="center" width="120"> 
          <template #default="scope">
            <span>{{ scope.row.transDate }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="originalValue" label="原值(元)" align="center" width="75"> 
          <template #default="scope">
            <span>{{ scope.row.originalValue }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="depreciationPeriods" label="预计使用月份" align="center" width="135"> 
          <template #default="scope">
            <span>{{ scope.row.depreciationPeriods }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" v-if="listQuery.type == 1" label="残值率(小数)" align="center" min-width="110"> 
          <template #default="scope">
            <span>{{ scope.row.rmValueRate }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cz" v-if="listQuery.type == 1" label="残值(元)" align="center" min-width="100"> 
          <template #default="scope">
            <span>{{ (scope.row.rmValueRate * scope.row.originalValue).toFixed(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="amortizeAmount" label="本月折旧(元)" align="center" min-width="105"> 
          <template #default="scope">
            <span>{{ scope.row.amortizeAmount }}</span>
          </template>
        </el-table-column>
        <el-table-column label="期末" align="center">
          <el-table-column prop="ljDepreciation" label="累计折旧(元)" align="center" min-width="105"> 
          <template #default="scope">
            <span>{{ (scope.row.ljDepreciation + scope.row.initLjDepreciation).toFixed(2) }}</span>
          </template>
        </el-table-column>
          <el-table-column prop="netValue" label="净值(元)" align="center" min-width="105"> 
            <template #default="scope">
              <span>{{ scope.row.netValue }}</span>
          </template>
        </el-table-column>
        </el-table-column>
        <el-table-column label="状态" align="center" min-width="50"> 
          <template #default="scope">
            <span v-if="scope.row.status == 2">暂停</span>
            <span v-else>正常</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作" align="center" width="150"> 
          <template #default="scope">
            <qzf-button jz="true" type="text" size="small" @success="handleUpdate(scope.row)">
              <i class="iconfont icon-bianji inconStyle" ></i>
            </qzf-button>
            <qzf-button jz="true" type="text" size="small" @success="handleModifyStatus(scope.row)">
              <i class="iconfont icon-shanchu inconStyle"></i>
            </qzf-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
      </div>
    </el-tabs>

    <addAssets ref="addAssets" :type="listQuery.type" @success="getList"></addAssets>

    <el-dialog :close-on-click-modal="false" destroy-on-close title="固定资产清理" v-model="clearStatus">
      <el-table
        :data="clearTemp"
        stripe
        style="width: 100%">
        <el-table-column label="资产名称" align="center" min-width="120">
          <template #default="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>

        <el-table-column label="资产清理科目" align="center" min-width="120">
          <template #default="scope">
            <subject-list v-model:subjectId="scope.row.clearSubjectId" v-model:fzhsItemId="scope.row.clearFzhsId" :codes="$findCode(['code1606'])"></subject-list>
          </template>
        </el-table-column>

        <el-table-column label="资产原值科目" align="center" min-width="120">
          <template #default="scope">
            <subject-list v-model:subjectId="scope.row.originalSubjectId" v-model:fzhsItemId="scope.row.originalFzhsId" :codes="$findCode(['code1601'])"></subject-list>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="clearStatus = false" size="small">取 消</el-button>
          <el-button type="primary" @click="clearClick" size="small">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
  <qzf-import-back :hideButton="true" @success="getList()" from="固定资产" ref="importBck" v-if="listQuery.type == 1"></qzf-import-back>
  <qzf-import-back :hideButton="true" @success="getList()" from="无形资产" ref="importBck" v-if="listQuery.type == 2"></qzf-import-back>
  <qzf-import-back :hideButton="true" @success="getList()" from="费用摊销" ref="importBck" v-if="listQuery.type == 3"></qzf-import-back>

  <printVouch ref="printVouch" name="assets"  :listQuery1="this.listQuery"></printVouch>

</template>



<script>
import { assetList , deleteEaAssets , jzAsset , cancelAsset , assetTotalSaves , clearAsset} from '@/api/assets'
import { exportFixedAssets } from "@/api/export"


import addAssets from "./components/addAssets.vue"
// import QzfButton from '../../../components/qzf/qzf-button.vue'
import printVouch from "../../bookkeeps/components/printVouch.vue"

export default {
  name: "assets",
  components:{
    addAssets,
    // QzfButton,
    printVouch
  },
  data() {
    return {
      list: [],
      sumList:[],
      listQuery: {
        assetType: '',
        page: 1,
        limit: 20,
        type: 1,
        comId:this.$store.getters['user/comInfo'].comId*1,
        period:this.$store.getters['user/comInfo'].period,
      },
      total:0,
      selects:[],
      clearStatus: false,
      clearTemp:[{}],//固定资产清理
      comId: this.$store.getters['user/comInfo'].comId*1,
      ids:[]

    }
  },
  deactivated(){
    if(!this.$getCachedViews("assets")){//销毁 mitt bus
      this.$bus.off("assetsUpdate")
    }
  },
  created() {
    this.contentStyleObj= this.$getHeight(300)
    this.getList()
    this.initBus()
  },
  methods:{
    initBus(){
      this.$bus.on("assetsUpdate", () => {
        this.getList()
      });
    },
    getList(){
      assetList(this.listQuery).then(res=>{
        if(res.data.data.list){
          this.list = res.data.data.list
          this.sumList = res.data.data.sum
          this.total = res.data.data.total
        }else{
          this.list = []
          this.sumList = []
        }
      })
    },
    handleCreate() {
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      this.$refs.addAssets.openDialog()
    },
    handleCommand(command) {
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      if(command == 'a'){
        if(this.selects.length == 0){
          this.$qzfMessage("请选择", 1)
          return
        }
        let param = {
          items:this.selects,
          type:1
        }
        cancelAsset(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("折旧成功")
            this.getList()
          }
        })
      }else if(command == 'b'){
        if(this.selects.length == 0){
          this.$qzfMessage("请选择", 1)
          return
        }
        let param = {
          items:this.selects,
          type:2
        }
        cancelAsset(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("取消折旧成功")
            this.getList()
          }
        })
      }else if(command == 'c'){
        if(this.selects.length == 0){
          this.$qzfMessage("请选择", 1)
          return
        }
        this.selects.map(v=>{
          v.status = 1
        })
        assetTotalSaves(this.selects).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("修改成功")
            this.getList()
          }
        })
      }else if(command == 'd'){
        if(this.selects.length == 0){
          this.$qzfMessage("请选择", 1)
          return
        }
        this.selects.map(v=>{
          v.status = 2
        })
        assetTotalSaves(this.selects).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("修改成功")
            this.getList()
          }
        })
      }else if(command == 'e'){
        jzAsset().then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("结转成功")
            this.getList()
          }
        })
      }else if(command == 'f'){
        if(this.selects.length == 0){
          this.$qzfMessage("请选择", 1)
          return
        }
        let arr = []
        this.selects.map(v=>{
          this.list.map(asset=>{
            if(v.id == asset.id){
              arr.push({id:v.id*1,name:asset.name,clearSubjectId:"",originalSubjectId:""})
            }
          })
        })
        this.clearTemp = arr
        this.clearStatus = true
      }
    },
    handleSelectionChange(val) {
      this.selects = val;
      this.ids = []
      val.map(item=>{
        this.ids.push( item.id )
      })
    },
    assetsDelete(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择", 1)
        return
      }
      deleteEaAssets(this.selects).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("删除成功")
          this.getList()
        }
      })
    },
    handleModifyStatus(row){
      deleteEaAssets([row]).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("删除成功")
          this.getList()
        }
      })
    },
    handleUpdate(row){
      this.$refs.addAssets.openDialog(row)
    },
    clearClick(){
      let status = false
      this.clearTemp.map(v=>{
        if(!v.clearSubjectId || !v.originalSubjectId){
          status = true
        }
      })
      if(status){
        this.$qzfMessage('请补充完整科目',2);
        return
      }
      clearAsset(this.clearTemp).then(res=>{
        if(res.data.msg == 'success'){
          this.getList()
          this.clearStatus = false
          this.$qzfMessage('清理成功');
          this.$bus.emit('voucherUpdate')
        }
      })
    },
    openCom(){
      this.$refs.importBck.handImport()
    },
    getSummaries(param){
      let { columns, data } = param;
      data = this.sumList
      let amortizeAmount = 0
      let count = 0

      let initLjDepreciation = 0
      let ljDepreciation = 0

      let netValue = 0
      let originalValue = 0
      let cz = 0
      
      amortizeAmount = data.amortizeAmount
      count = data.count

      initLjDepreciation = data.initLjDepreciation
      ljDepreciation = data.ljDepreciation

      netValue = data.netValue
      originalValue = data.originalValue
      cz = data.cz
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }if(column.property == "amortizeAmount"){
          sums[index] = amortizeAmount
        }else if(column.property == "count"){
          sums[index] = count
        }else if(column.property == "initLjDepreciation"){
          sums[index] = initLjDepreciation
        }else if(column.property == "ljDepreciation"){
          sums[index] = ljDepreciation
        }else if(column.property == "cz"){
          sums[index] = cz
        }else if(column.property == "netValue"){
          sums[index] = netValue
        }else if(column.property == "originalValue"){
          sums[index] = originalValue
        }else {
          sums[index] = '';
        }
      });
      return sums;
    },
    printAssets(){
      this.$refs.printVouch.init([this.comId],this.listQuery.period,this.ids)
    },
    exportAssets(){
      let param = {
        bookNames:['assets'],
        type:'pdf',
        query:this.listQuery,
        ids:this.ids,
        comIds:[this.comId],
        period:this.listQuery.period
      }
      param.query.comIds = [this.comId]
      exportFixedAssets(param).then(res => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url); 
        }
      })
    },
    handleClick(tab) {
      if(tab.props.name == 2){
        this.listQuery.type = 2
      }else if (tab.props.name == 3){
        this.listQuery.type = 3
      }else if (tab.props.name == 1){
        this.listQuery.type = 1
      }
      this.getList()
    },
  }
}
</script>

<style lang="scss" scoped>
.right_btns{
  width: 60%;
  float: right;
  text-align: right;
  margin-bottom: 10px;
}
.left_search{
  width: 39%;
  float: left;
  text-align: left;
}
.inconStyle{
  // margin: 0 5px;
  cursor: pointer;
  color: $color-main;
  font-size: 18px;
  line-height: 24px;
}
.pagination{
  margin-top: 15px;
}
</style>